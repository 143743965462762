// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CALENDAR */

.ant-calendar {
  border: 1px solid #e9e9e9;
}
.ant-calendar-picker-icon {
  position: absolute;
  top: 35%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

.ant-fullcalendar-month-select {
  margin-left: 5px;
}

.ant-calendar-picker-container {
  animation-duration: 0s !important;
}

.ant-calendar-range .ant-calendar-input-wrap {
  height: 38px;
}
